export default function initScrollDown() {
  const scrollDownButton = document.getElementById("js-scroll-down");

  if (scrollDownButton) {
    scrollDownButton.addEventListener("click", () => {
      document.getElementById("js-sidebar")?.scrollIntoView({
        behavior: "smooth",
      });
    });
  }
}
