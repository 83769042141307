import React from "react";
import { createRoot } from "react-dom/client";
import Modal from "./modal";

export default function initNewsletterModalFromHtmlElement() {
  const modalButton = document.getElementById("js-newsletter-modal-button");

  if (modalButton) {
    modalButton.addEventListener("click", () => {
      document.body.insertAdjacentHTML(
        "beforebegin",
        `<div id="js-newsletter-modal"></div>`
      );

      const rootNode = document.getElementById("js-newsletter-modal");

      if (!rootNode) {
        return;
      }

      const root = createRoot(rootNode);

      root.render(
        <Modal onClose={() => rootNode.remove()}>
          <div className="max-h-[80vh] bg-white rounded-sm sm:rounded-lg p-[16px] sm:p-[40px] overflow-scroll">
            <iframe
              src="https://demnext.substack.com/embed"
              width="100%"
              height="320"
            ></iframe>
          </div>
        </Modal>
      );
    });
  }
}
