export default function initSubstackFeed() {
  const substackElements = document.querySelectorAll<HTMLElement>(
    "#substack-feed-embed"
  );

  if (substackElements.length > 0) {
    (window as any).SubstackFeedWidget = {
      substackUrl: "demnext.substack.com",
      posts: 3,
    };

    const src = "//substackapi.com/embeds/feed.js";
    const script = document.createElement("script");

    script.src = src;

    script.onerror = () => {
      console.error(`Error loading script ${src}`);
    };

    script.async = true;

    document.head.appendChild(script);
  }
}
