import initBarba from "./barba";
import initDemocracyMap from "./democracy-map";
import initMenu from "./menu";
import initNewsletterModalFromHtmlElement from "./newsletter-modal";
import initSubstackFeed from "./substack-feed";
import initTopicModalFromHtmlElement from "./topic-modal";

initWebsite();
initAssemblyGuide();
initTopicModalFromHtmlElement();

function initWebsite() {
  initDemocracyMap();
  initMenu();
  initNewsletterModalFromHtmlElement();
  initSubstackFeed();
}

function initAssemblyGuide() {
  initBarba();
}
