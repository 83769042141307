export default function initReadingProgress() {
  const readingProgresses = document.querySelectorAll(".js-reading-progress");

  readingProgresses.forEach((element) => {
    window.addEventListener("scroll", () => {
      const documentElement = document.documentElement;
      const scrollTop = documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight =
        documentElement.scrollHeight || document.body.scrollHeight;
      const percent =
        (scrollTop / (scrollHeight - documentElement.clientHeight)) * 100;
      const progressBar = element.querySelector("span");

      if (progressBar) {
        progressBar.style.width = `${percent}%`;
      }
    });
  });
}
