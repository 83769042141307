export default function initActiveNavLinks() {
  const menuTitle = document.getElementById("js-menu-title");
  const childSection = document.querySelectorAll(".js-child-section");
  const cachedTitle = menuTitle?.textContent;

  window.addEventListener("scroll", () => {
    if (cachedTitle && childSection[0]?.getBoundingClientRect().top > 0) {
      menuTitle.textContent = cachedTitle;
    }

    childSection.forEach((element) => {
      const link = document.querySelector(`a[href='#${element.id}']`);

      if (!menuTitle?.textContent || !link?.textContent) {
        return;
      }

      if (
        element.getBoundingClientRect().top <= 0.5 &&
        element.getBoundingClientRect().bottom >= 0.5
      ) {
        menuTitle.textContent = link?.textContent;
        link?.classList.add("active");
      } else {
        link?.classList.remove("active");
      }
    });
  });
}
