import React from "react";
import { createRoot } from "react-dom/client";
import { z } from "zod";
import Modal from "./modal";
import classNames from "classnames";

export const schema = z.object({
  id: z.number(),
  headline: z.string(),
  image: z
    .object({
      url: z.string(),
      alt: z.string().nullable(),
      sizes: z.string(),
    })
    .nullable()
    .optional(),
  text: z.string(),
  color: z.string().default("pink"),
  positionX: z.number().optional(),
  positionY: z.number().optional(),
});

export type Topic = z.infer<typeof schema>;

export default function initTopicModalFromHtmlElement() {
  document
    .querySelectorAll<HTMLElement>(".js-topic-modal-button")
    .forEach((modalButton) =>
      modalButton.addEventListener("click", () => {
        const data = schema.safeParse(
          JSON.parse(modalButton.dataset.modal ?? "")
        );

        if (data.success) {
          document.body.insertAdjacentHTML(
            "beforebegin",
            `<div id="js-topic-modal"></div>`
          );

          const rootNode = document.getElementById("js-topic-modal");

          if (!rootNode) {
            return;
          }

          const root = createRoot(rootNode);

          root.render(
            <TopicModal {...data.data} onClose={() => rootNode.remove()} />
          );
        }
      })
    );
}

export function TopicModal({
  headline,
  image,
  text,
  color,
  onClose,
}: Topic & { onClose: () => void }) {
  return (
    <Modal onClose={() => onClose()}>
      <div
        className={`max-h-[80vh] overflow-scroll rounded-sm bg-${color} pb-[16px] pl-[16px] pr-[44px] pt-[16px] sm:rounded-lg sm:p-[40px]`}
      >
        <h4 className="mb-[24px] mr-[32px] font-heldane text-lg font-semibold leading-none sm:mb-[40px] sm:text-2xl">
          {headline}
        </h4>
        <div className="sm:mr-[30px] sm:flex">
          {image ? (
            <div className="mb-[24px] mr-0 sm:mb-0 sm:mr-[32px] sm:w-[35%]">
              <img
                className="mx-auto w-[70%] sm:mx-0 sm:w-auto"
                src={image.url}
                sizes={image.sizes}
                alt={image.alt ? image.alt : ""}
                loading="lazy"
              />
            </div>
          ) : null}
          <div
            className={classNames({ "sm:w-[438px]": image })}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </div>
    </Modal>
  );
}
