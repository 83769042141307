export default function initSidebar() {
  const sidebar = document.getElementById("js-sidebar");
  const sidebarNav = document.getElementById("js-sidebar-nav");
  const sidebarToggle = document.getElementById("js-sidebar-toggle");
  const menuToggle = document.getElementById("js-menu-toggle");

  if (menuToggle && sidebarNav) {
    menuToggle.addEventListener("click", () => {
      sidebarNav.classList.toggle("menu-open");
    });
  }

  if (sidebar && sidebarNav && sidebarToggle) {
    sidebarToggle.addEventListener("click", () => {
      if (sidebar.classList.contains("active")) {
        sidebar.classList.remove("active");
        sidebarNav.classList.remove("sidebar-open");
      } else {
        sidebar.classList.add("active");
        sidebarNav.classList.add("sidebar-open");
      }
    });

    const setNavPosition = () => {
      if (sidebar.getBoundingClientRect().top <= 0) {
        sidebarNav.classList.add("sidebar-fixed");
      } else {
        sidebarNav.classList.remove("sidebar-fixed");
      }
    };

    setNavPosition();

    window.addEventListener("scroll", () => setNavPosition());
    window.addEventListener("resize", () => setNavPosition());
  }
}
