export default function initForceDetailsElementOpen() {
  window.addEventListener("beforeprint", () => {
    document
      .querySelectorAll("details")
      .forEach((element) => element.setAttribute("open", ""));
  });

  window.addEventListener("afterprint", () => {
    document
      .querySelectorAll("details")
      .forEach((element) => element.removeAttribute("open"));
  });
}
