import barba from "@barba/core";
import initActiveNavLinks from "./active-nav-links";
import initAddBackToTop from "./add-back-to-top";
import initForceDetailsElementOpen from "./force-details-element-open";
import initLanguageSwitch from "./language-switch";
import initReadingProgress from "./reading-progress";
import initScrollDown from "./scroll-down";
import initSidebar from "./sidebar";
import initTopicModalFromHtmlElement from "./topic-modal";
import { gsap } from "gsap";

export default function initBarba() {
  initBase();
  initSidebar();

  if (document.querySelector("[data-barba]")) {
    barba.init({
      views: [
        {
          namespace: "guide",
          beforeLeave() {
            document.getElementById("hero")?.remove();
          },
          beforeEnter(data) {
            if (data.trigger === "forward" || data.trigger === "back") {
              console.log("return");
              window.location.reload();
              return;
            }

            initBase();
            initTopicModalFromHtmlElement();

            const link = document.querySelector(
              `.js-section-page a[href="${window.location.href}"]`
            );

            if (link) {
              document
                .querySelectorAll(".js-section-page a")
                .forEach((element) =>
                  element.parentElement?.classList.remove("active")
                );

              link?.parentElement?.classList.add("active");
              link?.classList.add("active");
            }
          },
        },
      ],
      transitions: [
        {
          name: "default-transition",
          enter(data) {
            gsap.from(data.next.container, {
              opacity: 0,
              duration: 0.5,
            });
          },
        },
      ],
    });

    barba.hooks.enter(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
    });
  }
}

function initBase() {
  initActiveNavLinks();
  initAddBackToTop();
  initForceDetailsElementOpen();
  initLanguageSwitch();
  initReadingProgress();
  initScrollDown();
}
