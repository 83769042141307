import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import {
  TransformWrapper,
  TransformComponent,
  KeepScale,
} from "react-zoom-pan-pinch";
import { Topic, TopicModal, schema as topicSchema } from "./topic-modal";
import { z } from "zod";
import { useInView, motion } from "framer-motion";

export const schema = z.object({
  headline: z.string().nullable(),
  fromTextDesktop: z.string().nullable(),
  toTextDesktop: z.string().nullable(),
  fromTextMobile: z.string().nullable(),
  toTextMobile: z.string().nullable(),
  topics: z.object({
    electoral: z.array(topicSchema),
    democratic: z.array(topicSchema),
  }),
});

export type Props = z.infer<typeof schema>;

export default function initDemocracyMap() {
  document
    .querySelectorAll<HTMLElement>(".js-democracy-map")
    .forEach((rootNode) => {
      const data = schema.safeParse(
        JSON.parse(rootNode.dataset.democracyMap ?? "")
      );

      if (data.success) {
        const root = createRoot(rootNode);
        root.render(<DemocracyMap {...data.data} />);
      }
    });
}

function DemocracyMap(props: Props) {
  const [topic, setTopic] = useState<Topic | null>(null);
  const transformWrapperRef = useRef(null);
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [enterCount, setEnterCount] = useState(0);

  useEffect(() => {
    if (isInView) {
      setEnterCount(enterCount + 1);
    }
  }, [isInView]);

  return (
    <>
      {topic && <TopicModal {...topic} onClose={() => setTopic(null)} />}
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
        ref={transformWrapperRef}
        wheel={{ wheelDisabled: true }}
      >
        {({ zoomIn, zoomOut }) => (
          <div className="mx-auto mb-[80px] w-full max-w-screen-2xl px-[16px] sm:mb-[112px] sm:px-[40px]">
            <div className="sm:grid sm:grid-cols-12 sm:gap-x-6 sm:px-0">
              <div className="sm:col-span-12 xl:col-span-10 xl:col-start-2">
                <div className="mb-[24px] flex items-center justify-center">
                  {props.headline && (
                    <h3 className="pr-[30px] font-propos text-xs leading-[1.2] sm:text-lg">
                      {props.headline}
                    </h3>
                  )}
                  <div className="ml-auto flex">
                    <button
                      className="group mr-[8px] flex h-[40px] w-[40px] items-center justify-center rounded-[8px] bg-pink transition-colors hover:bg-black"
                      onClick={() => zoomOut()}
                    >
                      <svg
                        className="stroke-black group-hover:stroke-white"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          x1="1"
                          y1="12"
                          x2="23"
                          y2="12"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                    <button
                      className="group flex h-[40px] w-[40px] items-center justify-center rounded-[8px] bg-green transition-colors hover:bg-black"
                      onClick={() => zoomIn()}
                    >
                      <svg
                        className="stroke-black group-hover:stroke-white"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path
                            d="M12 1L12 23"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <line
                            x1="1"
                            y1="12"
                            x2="23"
                            y2="12"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
                <motion.div
                  className="mb-[24px]"
                  ref={ref}
                  animate={{ opacity: enterCount >= 1 ? 1 : 0 }}
                  transition={{ delay: 0.2 }}
                >
                  <TransformComponent wrapperClass="relative w-full cursor-grab">
                    {[
                      { topics: props.topics.electoral, color: "coral" },
                      { topics: props.topics.democratic, color: "yellow" },
                    ].map(({ topics, color }) =>
                      topics.map((topic) =>
                        topic.positionX && topic.positionY ? (
                          <Pin
                            key={topic.id}
                            headline={topic.headline}
                            x={topic.positionX}
                            y={topic.positionY}
                            color={color}
                            onClick={() => setTopic(topic)}
                          />
                        ) : null
                      )
                    )}
                    <img
                      src="/assets/images/democracy-map.jpg"
                      loading="lazy"
                    />
                  </TransformComponent>
                </motion.div>
                {props.fromTextDesktop &&
                  props.fromTextMobile &&
                  props.toTextDesktop &&
                  props.fromTextMobile && (
                    <h4 className="flex justify-between pr-[30px] font-propos text-xs leading-[1.2] sm:text-lg">
                      <span className="hidden lg:block">
                        {props.fromTextDesktop}
                      </span>
                      <span className="block lg:hidden">
                        {props.fromTextMobile}
                      </span>
                      <span className="relative mx-[10px] flex-1 overflow-hidden bg-arrow bg-right bg-no-repeat px-[16px]" />
                      <span className="hidden lg:block">
                        {props.toTextDesktop}
                      </span>
                      <span className="block lg:hidden">
                        {props.toTextMobile}
                      </span>
                    </h4>
                  )}
              </div>
            </div>
          </div>
        )}
      </TransformWrapper>
    </>
  );
}

function Pin({
  headline,
  x,
  y,
  color,
  onClick,
}: {
  headline: string;
  x: number;
  y: number;
  color: string;
  onClick: () => void;
}) {
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <motion.div
      ref={ref}
      animate={{ opacity: isInView ? 1 : 0 }}
      transition={{ delay: 0.6 }}
      className="absolute translate-x-[-50%] translate-y-[-50%] hover:z-[1]"
      style={{
        left: `${x}%`,
        top: `${y}%`,
      }}
    >
      <KeepScale>
        <button
          className={`group absolute flex h-[20px] w-max cursor-pointer items-center justify-center rounded-[10px] md:h-[38px] hover:h-auto md:rounded-[19px] bg-${color} px-[10px] md:px-[20px]`}
          onClick={() => onClick()}
        >
          <span className="hidden items-center font-propos text-2xs leading-snug group-hover:flex md:text-sm sm:min-h-[38px] py-[5px] max-w-[100px] sm:max-w-[150px] lg:max-w-[200px]">
            {headline}
          </span>
        </button>
      </KeepScale>
    </motion.div>
  );
}
