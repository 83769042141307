import classNames from "classnames";
import React from "react";

export default function Modal({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose: () => void;
}) {
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    setActive(true);
  }, []);

  React.useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    if (active) {
      document.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [active]);

  return (
    <div
      id="modal"
      className={classNames("group", { active })}
      onClick={(e) => {
        if (
          (e.target as HTMLElement)?.parentElement?.className.includes("group")
        ) {
          onClose();
        }
      }}
    >
      <div className="fixed left-0 top-0 z-[2] flex h-full w-full items-center justify-center bg-modal px-[16px] opacity-0 group-[.active]:opacity-100 group-[.active]:transition-opacity">
        <div className="relative w-[800px]">
          <button
            className="absolute right-[16px] top-[16px] h-[24px] w-[24px] transition-opacity hover:opacity-60 sm:right-[50px] sm:top-[52px]"
            type="button"
            onClick={() => onClose()}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.5L10 10.5"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22.5 1.5L13.5 10.5"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22.5 23L13.5 14"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 23L10 14"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {children}
        </div>
      </div>
    </div>
  );
}
